export default () => {
    $(window).on('load', function () {
        let page_location = window.location.origin + "/wp-content/uploads/UKESF-GDPR-Client-Privacy-Notice-Process-2022-1.pdf";

        window.cookieconsent.initialise({
            "showLink": false,
            "content": {
                "message": "This website uses cookies to improve your experience. Please see our <a href=\"" + page_location + "\">Privacy Policy</a> for more information.",
                "dismiss": "Continue",
                "href": page_location
            }
        })
    });
}

import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, EffectFade]);

export default () => {
    new Swiper(".swiper", {
        slidesPerView: 4,
        spaceBetween: 15,
        autoHeight: false,
        preloadImages: true,
        preventClicks: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 25
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 30
            },

            767: {
                slidesPerView: 4,
                spaceBetween: 50
            }
        }
    });
}
export default () => {
    let $backButton = $("a.backToTop");
    let scrollAmount = 100;

    $(document).scroll(() => {
        let pos = $(window).scrollTop();

        (pos >= scrollAmount) ? $backButton.addClass('active') : $backButton.removeClass('active');
    });

    $backButton.on('click', function(e){
        e.preventDefault();

        $("html, body").animate({
                scrollTop:0
            },
            {
                duration: 800
            });
    });
}